.data-list.data-list--transactions {
  .data-list-grid {
    .data-row {
      grid-template-columns: calc(2em + 6em) auto calc(2em + 8em) calc(2em + 4.5em);

      .data-list-field {
        &.data-list-field--amount,
        &.data-list-field--balance {
          justify-content: flex-end;
        }
      }

      .data-list-field--actions {
        grid-template-columns: repeat(3, 1fr);
      }

      &.data-row-handled {
        color: darken(#e8e6e3, 40%);
      }
    }
  }
}
