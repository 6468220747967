.data-list.data-list--invoices {
  .data-list-grid {
    .data-row {
      grid-template-columns: calc(2em + 10em) calc(2em + 10em) auto calc(2em + 6em) calc(2em + 6em);

      .data-list-field.data-list-field--totalPrice {
        justify-content: flex-end;
      }

      .data-list-field--actions {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
