@import "./styles/forms";

body {
  font-size: 0.9rem;

  .app {
    text-align: center;
    margin-bottom: 5rem;

    .page {
      max-width: calc(1240px - 10em);
      margin: 25px auto 0;

      .page-header {
        position: relative;

        .page-header-actions {
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .react-pdf__Page {
        canvas {
          margin: 0 auto;
        }
      }
    }
  }
}
