.app-header {
  padding: 0 30px;
  height: 5em;
  background-color: darken(#282c34, 10%);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-content: space-between;

  .app-logo {
    left: 30px;
    height: 40px;
    pointer-events: none;
  }

  h1 {
    font-size: 2rem;
  }

  nav.main-nav {
    display: flex;
    justify-self: flex-end;

    .btn {
      line-height: 5em - 0.4em;
    }
  }
}
