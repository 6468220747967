$form-line-height: 2em;

form {
  margin-bottom: 4em;
}

.form-body {
  width: 320px;
  margin: 0 auto;

  &.form-body--full-width {
    width: 80%;
  }
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: $form-line-height;
  margin-bottom: 1em;

  > * {
    width: 100%;
  }

  label,
  .label {
    text-align: left;
  }

  .form-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 $form-line-height;

    input,
    select {
      flex: 1;
      font-family: sans-serif;
      background: #fff;
      border: 1px solid #282c34;
      padding-left: 0.5em;
      outline: none;
      line-height: $form-line-height;
      height: $form-line-height;
      width: 100%;

      &[type="checkbox"] {
        flex: 0 0 $form-line-height;
        height: $form-line-height * 0.8;
        margin: 0;
        cursor: pointer;
      }
      &[type="file"] {
        background: none;
      }
    }

    .form-group-actions {
      > .btn {
        width: $form-line-height;
      }
    }
  }
}

.form-actions {
  margin-top: 1.8em;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-left: 0.6em;
  }
}

.form-error {
  background: lighten(crimson, 50);
  color: crimson;
  margin: 1em 20%;
  padding: 0.6em;
  font-size: 0.8em;
}
