
@mixin colored-button($bg-color, $color) {
  border: 1px solid darken($bg-color, 8);
  background: darken($bg-color, 8);
  color: darken($color, 8);

  &:not(.btn--disabled):hover {
    background: $bg-color;
    color: $color;
  }
}

@mixin transparent-button($color) {
  border: 1px solid darken($color, 20);
  color: darken($color, 20);

  &:not(.btn--disabled):hover {
    border-color: $color;
    color: $color;
  }
}

.btn {
  display: flex;
  align-items: center;
  border: 0;
  padding: 0.2em 1em;
  text-decoration: none;
  color: rgba(222, 82, 0, 0.6);
  cursor: pointer;

  &:not(.btn--disabled):hover {
    color: rgba(222, 82, 0, 1);
  }

  &.btn--disabled {
    color: rgba(222, 82, 0, 0.3);
  }

  &.btn--active {
    color: rgba(222, 82, 0, 1);
  }

  &.btn--icon-only {
    padding: 0;

    .btn-icon {
      width: 100%;
      margin-right: 0;
    }
  }

  .btn-icon {
    width: 1.6em;
    margin-right: 5px;
    display: flex;
    align-items: center;

    svg {
      width: 100%;
    }
  }

  &.btn--primary {
    @include colored-button(#ca4a00, #fff);
  }

  &.btn--transparent {
    @include transparent-button(#fff);
  }

  &.btn--secondary {
    @include colored-button(#ca4a00, #fff);
  }

  &.btn--disabled {
    cursor: default;
    &:hover {

    }
  }
}
