.data-list {
  .data-list-grid {
    .data-row {
      min-height: 50px;
      display: grid;
      align-items: center;
      text-align: left;
      grid-auto-rows: minmax(min-content, max-content);

      .data-list-field {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        height: 100%;
        padding: 1em;

        &.data-list-field--header {
          font-weight: bold;
          border-bottom: 1px solid #fff;
        }

        &.data-list-field--sortable {
          justify-content: flex-start;
          width: auto;

          .data-list-field-sort {
            display: inline-block;
            margin-left: 0.7em;

            .btn {
              width: 1.5em;
              height: 0.8em;
            }
          }
        }

        &.data-list-field--actions {
          .data-list-row-action {
            width: 1.5em;
            height: 1.5em;

            .btn {
              width: 1.5em;
            }
          }
        }
      }
    }
  }

  .data-list-filter {
    display: flex;

    .form-group {
      margin-right: 1em;
    }
  }

  .data-list-pager {
    display: flex;
    line-height: 3em;
    height: 3em;
    justify-content: space-between;
    align-items: center;

    > * {
      margin: 0 5px;
    }

    .page-shifter {
      display: flex;
      align-items: center;

      .btn .btn-icon {
        width: 1.8em;
      }
    }

    .page-size-shifter {
      display: flex;
      align-items: center;

      .btn {
        padding-right: 0.5em;
        padding-left: 0.5em;
      }
    }
  }
}
