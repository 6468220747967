.invoice-lines {
  margin: 3em auto;
  width: 70vw;
}
.invoice-line {
  display: grid;
  grid-template-columns: 2em auto 4em 4em 4em 4em;
  align-items: center;
  text-align: left;
  line-height: 2em;

  &.invoice-line--header {
    border-bottom: 1px solid #fff;
  }

  .invoice-line-actions {
    display: flex;
    justify-content: space-between;

    .btn {
      flex: 0 0 2em;
    }
  }
}

.btn--add-invoice-line {
  width: auto;
}
