.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(15px);
  display: none;

  &.modal-wrapper--visible {
    display: block;
  }

  .modal {
    background: #131516;
    border: 1px solid #282c34;
    width: 60%;
    margin: 200px auto 0;

    &.modal--max-size {
      margin: 5vh 5vw;
      width: 90vw;
      height: 90vh;

      .modal-body {
        max-height: calc(90vh - 4em);
        padding: 0;
      }
    }

    .modal-header {
      background-color: darken(#282c34, 10%);
      padding: 0 1em 0 2em;
      line-height: 4em;
      height: 4em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #282c34;

      h2 {
        margin: 0;
      }

      .modal-actions {
        display: flex;

        .btn {
          margin-left: 0.5em;
          height: 1.6em;
          width: 1.6em;
        }
      }
    }

    .modal-body {
      padding: 2em 4em;
      max-height: 60vh;
      overflow-y: auto;
      background-color: #282c34;
    }
  }
}
