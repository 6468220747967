.draggable {
  position: relative;

  .overlay {
    border: dashed #de5200 4px;
    background-color: rgba(0,0,0,.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    .overlay-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #de5200;
      font-size: 2rem;
    }
  }
}
