.data-list--customers {
  .data-list-grid {
    .data-row {
      grid-template-columns: auto calc(2em + 10em) calc(2em + 8em) calc(2em + 3em);

      .data-list-field--actions {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
